import { createContext, useContext, useEffect, useState, useMemo } from 'react';
import { useLazyFetchUserPreferencesQuery } from '../services/userPreferences.api';
import {
  FetchChargerRequest,
  useLazyFetchChargerFeaturesQuery,
} from '../services/chargers.api';
import { FeatureInfo } from '../stores/types/userPreferences.interface';
import { useAuth } from './useAuth';

type ContextType = {
  userIsNotSelectedForPayGo: boolean;
  allowDriverImprovements: boolean;
};

const initalState: ContextType = {
  userIsNotSelectedForPayGo: false,
  allowDriverImprovements: false,
};

const UserPreferenceContext = createContext(initalState);

export const useUserPreference = () => {
  return useContext(UserPreferenceContext);
};

export const UserPreferenceProvider = (props: any) => {
  const { children } = props;
  const auth = useAuth();
  const chargerName = window.location.pathname.substring(1);
  const [userIsNotSelectedForPayGo, setUserIsNotSelectedForPayGo] = useState();
  const [triggerFetchUserPreferences, { data, isSuccess }] =
    useLazyFetchUserPreferencesQuery();

  const [triggerChargerFeatures, { data: feature }] =
    useLazyFetchChargerFeaturesQuery();

  const getFeatureFlagsMap = useMemo(() => {
    const featureFlagsMap: { [key: string]: boolean } = {};

    data?.features?.forEach((feature: FeatureInfo) => {
      featureFlagsMap[feature.featureName] = feature.enabled;
    });

    feature?.entities?.forEach((feature: FeatureInfo) => {
      featureFlagsMap[feature.featureName] = feature.enabled;
    });

    return featureFlagsMap;
  }, [data, feature]);

  useEffect(() => {
    if (isSuccess) {
      data?.cohort &&
        setUserIsNotSelectedForPayGo(JSON.parse(data.cohort).isNotPayGo);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (auth.user) {
      triggerFetchUserPreferences();
    }
  }, [auth]);

  useEffect(() => {
    if (chargerName) {
      triggerChargerFeatures({
        chargerName: chargerName,
      } as FetchChargerRequest);
    }
  }, [chargerName]);

  return (
    <UserPreferenceContext.Provider
      value={{
        userIsNotSelectedForPayGo,
        allowDriverImprovements:
          getFeatureFlagsMap.Y2024_driver_webapp_improvements || false,
      }}
    >
      {children}
    </UserPreferenceContext.Provider>
  );
};
